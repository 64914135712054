/* globals $, ga, appConfig, pageData, MediaQuery, Modernizr, Swiper, objectFitImages */

/* exported App */

'use strict';

var App = (function() {

    var config = {
            selectors: {
                window: window,
                document: document,
                body: 'body',

                header: '.js-header',
                pageTitle: '.js-page-title',

                navToggle: '.js-nav-toggle',

                smoothScrolling: '.js-smooth-scroll',
                containerScrolling: '.js-container-scroll',

                slideshow: '.js-slideshow',
                slideInner: '.js-slide-inner',
                slideContent: '.js-slide-content',
                slideshowPrevNext: '.js-slideshow-prevnext',
                slideshowPagination: '.js-slideshow-pagination',

                inlineSlideshow: '.js-inline-slideshow',

                projectListScrollContainer: '.js-project-list-scroll-container',

                projectIndexToggle: '.js-project-index-toggle',
                projectIndexToggleOff: '.js-project-index-toggle--off',
                projectIndexImage: '.js-project-index-image',

                slideoutTable: '.js-slideout-table',
                slideoutTableToggle: '.js-slideout-table-toggle',

                bioLongToggleContainer: '.js-bio-long-toggle-container',
                bioLongToggle: '.js-bio-long-toggle',
                bioLong: '.js-bio-long',

                textFullpage: '.js-text-fullpage',

                contentSlideContainer: '.js-content-slide-container',

                historyBack: '.js-history-back',
            },

            inlineSlideshowSettings: {
                _autoplay:  1700,
                speed: 450,
                loop: true,
                direction: 'horizontal',
                grabCursor: true,
                mousewheelForceToAxis: true,
                prevButton: '.js-slideshow-prev',
                nextButton: '.js-slideshow-next',
                buttonDisabledClass: 'is-disabled',
                paginationType: 'custom',
                paginationHide: false,
                paginationCustomRender: function (swiper, current, total) {
                    return current + '/' + total;
                },
                /*
                onTransitionEnd: function(swiper){
                    swiper.params.speed = 0;
                },
                onTouchStart: function(swiper){
                    swiper.params.speed = 600;
                },
                onTransitionStart: function (swiper) {
                    swiper.params.speed = 600;
                },
                */
            },

            slideshowSettings: {
                speed: 750,
                direction: 'horizontal',
                grabCursor: true,
                keyboardControl: true,
                mousewheelControl: false,
                mousewheelSensitivity: 1,
                mousewheelInvert: true,
                mousewheelForceToAxis: true,
                longSwipesRatio: 0.25,
                prevButton: '.js-slideshow-prev',
                nextButton: '.js-slideshow-next',
                buttonDisabledClass: 'is-disabled',
                hashnav: true,
                hashnavWatchState: false,
                replaceState: true,
            },

            fullpage: {
                transition: 800,
                activationKeys: {
                    offsetSections: 'c3RlZmFuaWVtb3NoYW1tZXIuY29tX24yWWIyWm1jMlYwVTJWamRHbHZibk09dGI2',
                },
                offsetSectionHeight: 85,
                offsetSectionCentered: false,
            },

            smoothScrollingSpeed: 600,
            scrollTolerance: 30,
            objectFitInterval: 3000,

            overlayTransitionDuration: 750,

            monitorMouseActivity: false,
        },

        selectors = {},

        elements = {}, $e,



    init = function(_config) {
        // Extend module config
        $.extend(true, config, _config || {});

        // Cache all elements for later use
        cacheElements();

        // DOM and event bindings
        setupBindings();

        // Pub/sub
        setupEvents();

        // Object-fit images
        objectFitPolyfill();

        // Fore full reload on back navigation
        forceReloadOnBackNavigation();

        // Fullpage project/info slides
        setupFullpageSlides();

        // Determine if mouse is idle
        monitorMouseActivityStatus();

        // Show long bio text on click
        shortLongBioToggle();

        // On arriving at homepage slideshow end: open work index
        openIndexOnSlideshowEnd();

        // Grid overlay
        gridOverlay();
    },



    cacheElements = function() {
        // Iterate over all selectors in config and store them as jQuery objects
        $.each(config.selectors, function(elementName, selector) {
            elements[elementName] = $(selector);
        });

        // Shortcuts
        selectors = config.selectors;
        $e = elements;
    },


    // DOM bindings
    setupBindings = function() {

        // Toggle mobile navigation
        $(document).on('click', selectors.navToggle, function(event){
        	$('body').toggleClass('is-nav-visible');
        	event.preventDefault();
        });

    	// Hide nav/search on ESC key
        $(document).on('keyup keypress', function(event) {
            if (event.keyCode === 27 && $e.body.hasClass('is-nav-visible')) {
                $e.body.removeClass('is-nav-visible');
                event.preventDefault();
            } else if (event.keyCode === 27 && $e.body.hasClass('is-index-visible')) {
                $e.body.removeClass('is-index-visible');
                event.preventDefault();
            }
        });

        // Toggle project index
        $(document).on('click', selectors.projectIndexToggle, function(event){
        	$('body').toggleClass('is-index-visible');
        	event.preventDefault();
        });
        $(document).on('click', selectors.projectIndexToggleOff, function(event){
        	$('body').removeClass('is-index-visible');
        	event.preventDefault();
        });

        $(document).on('click', selectors.historyBack, function(event){
            event.preventDefault();

            var fallbackUrl = $(this).prop('href') || '/',
                prevPage = window.location.href;

            window.history.go(-1);

            setTimeout(function(){
                if (window.location.href == prevPage) {
                    window.location.href = fallbackUrl;
                }
            }, 500);
        });

    	// Make anchor links scroll smoothly
    	$(document).on('click', selectors.smoothScrolling, function(event){
            var $link = $(this),
                hash = this.href.split('#')[1],
                $target = $('#' + hash),
                $container,
                scrollTo = 0;

            if ($target.length) {
                $container = $target.scrollParent();
                if (!$container || $container[0] === document) {
                    $container = $('html, body');
                }
                scrollTo = $target.offset().top + $container.scrollTop();
                $container.animate({scrollTop: scrollTo}, config.smoothScrollingSpeed);
                $('body').removeClass('is-nav-visible');
                event.preventDefault();
            }
        });

        // Anchor links within scrollable containers
    	$(document).on('click', selectors.containerScrolling, function(event){
            var $link = $(this),
                $target = $($link.attr('href')),
                $container = $target.scrollParent();

            if (!$container || $container[0] === document) {
                $container = $('html, body');
            }

            // Offset - current scroll + link position
            var scrollTo = $target.offset().top + $container.scrollTop();
            $container.animate({scrollTop: scrollTo}, config.smoothScrollingSpeed);
        	event.preventDefault();
        });

        $e.projectListScrollContainer.on('scroll', function(){
            var scrollTop = $(this).scrollTop();
                $e.body.toggleClass('is-content-scrolled-down', scrollTop > config.scrollTolerance);
        });

        // Resizing? Save to body
        $(window).on('resizestart',   0, function(){ $('body').addClass('is-resizing'); });
        $(window).on('resizestop', 1000, function(){ $('body').removeClass('is-resizing'); });

        // Breakpoint change
        $.subscribe('/mediaquery/change', function(_, size) {
            if (MediaQuery.atLeast('small')) {

            }
        });

    	// Re-initialize page after ajax page loads
    	$(window).on('statechangecomplete', onStateChange);

        // "Fake" state change to initialize everything now
    	onStateChange();
    },

    // Observers for interaction with other modules
    setupEvents = function() {
        // Show and hide loading spinner when loading over Ajax
        $.subscribe('/ajax/start', function() { $('body').addClass('loading'); });
        $.subscribe('/ajax/stop',  function() { $('body').removeClass('loading'); });

        // Log all ajax errors to console
        $(document).ajaxError(function(event, jqXHR, settings, error) {
            console.error('Ajax request failed: ' + error + ' ('+settings.url+')');
        });
    },

    onStateChange = function() {
        openExternalLinksInNewTab();
        setupSlideshow();
        setupInlineSlideshows();
        slideoutTables();
    },

    pageHasTemplate = function(template) {
        return $('body').hasClass('template-' + template);
    },




    trackCustomEvent = function(category, action, label){
        ga('send', 'event', category, action, label);
    },

    objectFitPolyfill = function () {
        if (Modernizr.objectfit) {
            return;
        }

        if (typeof objectFitImages !== 'function') {
            return;
        }

        var triggerObjectFit = function () {
            objectFitImages(null, {watchMQ: true});
        };

        triggerObjectFit();
        setTimeout(triggerObjectFit, config.objectFitInterval / 2);
        setInterval(triggerObjectFit, config.objectFitInterval);
    },

    openExternalLinksInNewTab = function () {
        $('a')
            .filter('[href^="http"], [href^="//"]')
            .not('[href*="' + window.location.host + '"]')
            .attr('rel', 'noopener noreferrer')
            .attr('target', '_blank');
    },

    setupContentSlides = function () {
        $(selectors.contentSlideContainer).each(function(){
            var $container = $(this),
                $wrapper = $container.closest('.fp-section'),
                containerHeight = $wrapper.height(),
                occupiedHeight = 0,
                slides = [],
                slideIndex = 0,
                $blocks = $container.children();

            // console.log('CONTAINER ' + containerHeight + ' -------');

            $blocks.each(function(){
                var $block = $(this),
                    blockHeight = $block.outerHeight(true),
                    blockPreview = $block.text().substring(0, 20);

                if (blockHeight > containerHeight) {
                    // console.warn('Block larger than container', blockHeight, ' > ', containerHeight, blockPreview);
                    if (occupiedHeight > 0) {
                        slideIndex++;
                    }
                }
                else if (occupiedHeight + blockHeight > containerHeight) {
                    // console.info('Starting new slide', blockPreview);
                    slideIndex++;
                    occupiedHeight = 0;
                }
                occupiedHeight += blockHeight;
                // console.log('Adding ' + blockHeight + ' = ' + occupiedHeight, blockPreview);
                (slides[slideIndex] = slides[slideIndex] || []).push($block);
            });

            var slideNum = slides.length,
                currentSlideIndex = 0;

            if (slideNum > 1) {
                var $slideWrapper = $('<div class="info-slide-wrapper"></div>'),
                    $slideNav = $(
                        '<div class="info-slide-nav">' +
                            '<div class="info-slide-nav__column">' +
                                '<span class="info-slide-nav__button js-content-slide-next">More</span>' +
                            '</div>' +
                            '<div class="info-slide-nav__column">' +
                                '<span class="info-slide-nav__current js-content-slide-page">1</span>/' +
                                '<span class="info-slide-nav__total js-content-slide-pagenum">'+slideNum+'</span>' +
                            '</div>' +
                        '</div>'
                    );
                $slideWrapper.appendTo($container);
                $.each(slides, function(index, $content) {
                    var $slide = $('<div class="info-slide js-content-slide"></div>');
                    $slide.data('slide-index', index);
                    $slide.toggleClass('is-active', index === 0);
                    $slide.append($content).appendTo($slideWrapper);
                });

                var t = 0, $tallest;

                $slideWrapper.children().each(function() {
                    var $this = $(this),
                        height = $this.outerHeight();
                        // console.log('Height', height);
                        // console.log('Total', t);

                    if (height > t) {
                        $tallest = $this;
                        t = height;
                    }
                });

                $slideWrapper.children().addClass('is-not-tallest');

                if ($tallest && $tallest.length) {
                    $tallest.removeClass('is-not-tallest');
                }

                $slideNav.appendTo($slideWrapper);

                $('.js-content-slide-next', $slideNav).click(function(){
                    if (currentSlideIndex === slideNum - 1) {
                        currentSlideIndex = 0;
                    } else {
                        currentSlideIndex++;
                    }
                    $('.js-content-slide', $slideWrapper)
                        .removeClass('is-active')
                        .eq(currentSlideIndex).addClass('is-active');
                    $('.js-content-slide-page', $slideNav).html(currentSlideIndex+1);
                });
            }

            // console.log(slides);
        });
    },

    layoutFullpageSlides = function () {

        var $container = $e.textFullpage;

        // Save / restore original HTML
        if ($container.data('original-html')) {
            $.fn.fullpage.destroy('all');
            $container.html($container.data('original-html'));
        } else {
            $container.data('original-html', $container.html());
        }

        var $sections = $container.children();

        if (!MediaQuery.atLeast('large')) {
            return;
        }

        // Move to start of page
        $container.prependTo($e.body);

        // Add anchors
        var anchors = [];
        $sections.each(function(){
            var sectionAnchor = (anchors.length + 1).toString();
            anchors.push(sectionAnchor);
            $(this).attr('data-anchor', sectionAnchor);
        });

        // Add offset section config
        $sections.data({
            percentage: config.fullpage.offsetSectionHeight,
            centered: config.fullpage.offsetSectionCentered,
        });

        $container.fullpage({

            // Register extensions
            offsetSections: true,
            offsetSectionsKey: config.fullpage.activationKeys.offsetSections,

            // Pull header out of scrolling behaviour
            fixedElements: [selectors.header].join(','),

            // Ignore scrolling when over header
            normalScrollElements: selectors.header,

            continuousVertical: false,
			verticalCentered: false,
			scrollingSpeed: config.fullpage.transition,
			fitToSectionDelay: 250,

			// Don't animate scrolling on first load, jump directly
			animateAnchor: false,

			recordHistory: false,
			lazyLoading: false,

			afterLoad: function() {
    			console.log('--- Fullpage: afterLoad');

    			// Fullpage.js messes with autoplay

    			$('video[autoplay]').each(function(){
        			$(this)[0].play();
                });

			},

			afterSlideLoad: function() {
    			console.log('--- Fullpage: afterSlideLoad');
			},

			afterRender: function() {
                console.log('--- Fullpage: afterRender');
                setupContentSlides();
            },

            onLeave: function(index, nextIndex, direction) {
    			console.log('--- Fullpage: onLeave (nextIndex: ' + nextIndex + ')');
            }
        });
    },

    setupFullpageSlides = function() {

        // Abort if no project slides found or wrong page template
        if (!$e.textFullpage.length || !pageHasTemplate('info')) {
            return;
        }

        $(window).on('resizestop', 250, layoutFullpageSlides);
        layoutFullpageSlides();
    },

    slideoutTables = function () {
        $(selectors.slideoutTable).each(function(){
            var $container = $(this),
                $table = $container.children().slice(1),
                $toggle = $container.find(selectors.slideoutTableToggle);

            if ($table.length >= 1 && $toggle.length) {
                $table.slideUp();
                $toggle.first().click(function(){
                    $container.toggleClass('is-toggled');
                    $table.slideToggle();
                });
            }
        });
    },

    shortLongBioToggle = function() {
        $e.bioLong.slideUp();
        $e.bioLongToggle.click(function(){
            $e.bioLong.slideToggle();
            $e.bioLongToggleContainer.toggleClass('is-toggled');
        });
    },

    setupInlineSlideshows = function () {
        $e.inlineSlideshow.each(function(){
            var $slideshow = $(this);
            if ($slideshow.data('inited')) {
                return;
            }

            var slideshowConfig = $.extend({}, config.inlineSlideshowSettings),
                $pagination = $slideshow.find(selectors.slideshowPagination);
            if ($pagination.length) {
                slideshowConfig.pagination = $pagination[0];
            }
            if ($slideshow.is('[data-autoplay]')) {
                slideshowConfig.autoplay = slideshowConfig._autoplay;
            }

            var swiperInstance = new Swiper(this, slideshowConfig);
            $slideshow.data('inited', true);
            $slideshow.data('swiper', swiperInstance);
        });
    },

    monitorMouseActivityStatus = function () {
        if (!config.monitorMouseActivity) {
            return;
        }

        var timeout = null;

        $(document).on('mousemove click', function() {
            $e.body.removeClass('is-mouse-idle');
            clearTimeout(timeout);
            timeout = setTimeout(function() {
                $e.body.addClass('is-mouse-idle');
            }, 3000);
        });
    },

    setupSlideshow = function () {
        $e.slideshow.each(function(){
            var $slideshow = $(this);
            if ($slideshow.data('inited')) {
                return;
            }

            var cfg = config.slideshowSettings;

            cfg.onInit = function(swiper) {
                for(var i=0;i<swiper.slides.length;i++) {
                    var $slide = $(swiper.slides[i]),
                        $inner = $(selectors.slideInner, $slide);
                    $slide.data('inner', $inner);
                }
            };

            cfg.onSlideChangeEnd = function(swiper) {
                $e.body.toggleClass('is-slideshow-at-end', swiper.isEnd);
                $e.body.toggleClass('is-slideshow-at-beginning', swiper.isBeginning);

                if (swiper.isEnd && !swiper.isBeginning) {
                    $.publish('/slideshow/end', $slideshow);
                }
                if (swiper.isBeginning) {
                    $.publish('/slideshow/beginning');
                }

            };

            var swiperInstance = new Swiper(this, cfg);

            $slideshow.data('inited', true);
            $slideshow.data('swiper', swiperInstance);

            $e.projectIndexImage.on('click', function(){
                var slideIndex = $(this).data('slide-index');
                swiperInstance.slideTo(slideIndex, 0);
                $e.body.removeClass('is-index-visible');

                return;
                setTimeout(function(){
                    var distance = Math.abs(swiperInstance.activeIndex - slideIndex) - 1,
                        speed = config.slideshowSettings.speed * (1 + (0.25 * distance));

                    swiperInstance.slideTo(slideIndex, speed);
                }, config.overlayTransitionDuration * 0.8);
            });

            setupSlideshowCaptions();
        });

        $e.slideContent.find('a').click(function(event){
            event.stopPropagation();
        });
    },

    calculateSlideshowCaptionHeight = function () {
        var pageTitleHeight = 0;
        if (!MediaQuery.atLeast('medium')) {
            pageTitleHeight = $e.pageTitle.outerHeight() || 0;
        }
        $e.slideContent.each(function(){
            var $caption = $('figcaption:not(:empty)', this),
                captionHeight = $caption.outerHeight() || 0,
                captionAndTitleHeight = captionHeight + pageTitleHeight;
            $(this).get(0).style.setProperty('--caption-height', captionAndTitleHeight + 'px');
        });
    },

    setupSlideshowCaptions = function () {
        $(window).on('resizestop', 250, calculateSlideshowCaptionHeight);
        calculateSlideshowCaptionHeight();
    },

    forceReloadOnBackNavigation = function () {
        $(window).on('pageshow', function(event) {
            if (event.originalEvent.persisted) {
                window.location.reload();
            }
        });
    },

    openIndexOnSlideshowEnd = function () {
        $.subscribe('/slideshow/end', function(event, slideshow) {
            var $slideshow = $(slideshow),
                swiperInstance = $slideshow.data('swiper'),
                numSlides = swiperInstance.slides.length,
                loadUrl = $slideshow.data('load-on-end');

            if (!loadUrl) {
                return;
            }

            // Multiple slides: leave with transition
            if (numSlides > 2) {
                $e.body.addClass('is-leaving-page');
                setTimeout(function(){
                    window.location.href = loadUrl;
                }, 250);
            }

            // Single slide: leave without transition
            else {
                window.location.href = loadUrl;
            }
        });
    },

    gridOverlay = function () {

        if (!$e.body.hasClass('is-user-logged-in')) {
            return;
        }

        var keysdown = {},
            keyClasses = {
                71: 'is-grid-visible',
            };
        $(document).keydown(function(e){
            if (keysdown[e.which]) {
                return;
            }
            keysdown[e.which] = true;

            if (keyClasses[e.which]) {
                $e.body.addClass(keyClasses[e.which]);
            }
        });

        $(document).keyup(function(e){
            delete keysdown[e.which];
            if (keyClasses[e.which]) {
                $e.body.removeClass(keyClasses[e.which]);
            }
        });
    };


    // Public properties and methods
    return {
        init: init,
    };

})();

// General site/app module

$(function() {

    // Initialize media query detection

    MediaQuery.init();

    // Load up app and initialize

    App.init();

});
